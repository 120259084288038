import React from 'react'
import Section from '../../components/Section'
import BotaTextCircle from '../../components/BotaTextCircle'
import Button from '../../components/Button'
import ResDiaryBookingWidget from '../../components/ResDiaryBookingWidget'
import { graphql, useStaticQuery } from 'gatsby'
import ReactMarkdown from 'react-markdown'

import './BookATable.scss'

const BookATable = () => {
  const data = useStaticQuery(graphql`
    query BookATableQuery {
      markdownRemark(frontmatter: { templateKey: { eq: "book-a-table" } }) {
        frontmatter {
          instructions
        }
      }
    }
  `);

  const { instructions } = data?.markdownRemark?.frontmatter || {}

  return (
    <Section name="book-a-table" className="hero book-a-table wood-background">
      <div className="container is-gapless">
        <div className="columns">
          <div className="column is-one-third btc-offset is-hidden-touch">
              <div id="booking-links" className="booking-links">
                <BotaTextCircle alignCenter content={['BOOK','HERE']} colour="purple" />
                  <Button
                    href="/menus"
                    className="button is-danger is-block has-shadow"
                    text="View Menus"
                    icon="fa fa-caret-right"
                    internal={true}
                  />
              </div>
          </div>
          <div className="column is-booking-box">
              <ResDiaryBookingWidget />
          </div>
          <div className="column is-booking-box">
            <div className="booking-terms">
              <h1>Please note when booking</h1>
              <ReactMarkdown source={instructions} />
            </div>
          </div>
        </div>
    </div>
    </Section>
  )
}

export default BookATable;
