import React from 'react'
import PropTypes from 'prop-types'
// import MapboxGL from 'mapbox-gl'
import './Map.scss'

export default class Mapbox extends React.Component {

  componentDidMount() {
    window.mapboxgl.accessToken = 'pk.eyJ1IjoibWVtY2M4OCIsImEiOiJjajFqczh0b2owMDJ6MzNxbjdnMTNxcGh3In0.0LgRD3WqqgGA0Y_3MteKHQ';
    let map = new window.mapboxgl.Map({
      container: 'maps-placeholder',
      style: 'mapbox://styles/mapbox/light-v9',
      zoom: 16,
      center: [-1.782904823112176, 53.64777138891614]
      });
    map.addControl(new window.mapboxgl.NavigationControl());
    // Disable drag and zoom handlers.
    // if (map.dragging) map.dragging.disable();
    // if (map.touchZoom) map.touchZoom.disable();
    // if (map.doubleClickZoom) map.doubleClickZoom.disable();
    // if (map.scrollWheelZoom) map.scrollWheelZoom.disable();
    // if (map.wheelZoom) map.wheelZoom.disable();
    // if (map.keyboard) map.keyboard.disable();
    // if (map.tap) map.tap.disable();

    const popup = new window.mapboxgl.Popup({ closeOnClick: false })
    .setLngLat([-1.782904823112176, 53.64777138891614])
    .setHTML('<h1>Botafogo Rodizio Bar & Grill</h1><div><img src="/images/uploads/bota-28.jpg" style=" width: 300px;margin:12px auto;" /></div><div><h4 style="line-height:1.5;">37 John William Street <br />Huddersfield HD1 1BL</h4></div>')
    .addTo(map);
  }

  render() {
    return (
      <div id="maps-placeholder" />
    )
  }
}
