import React from 'react'
import PropTypes from 'prop-types'
import Section from '../../components/Section'
import Map from '../../components/Map'
import './ContactUs.scss'

import NewsletterForm from '../../components/NewsletterForm'
import OpeningHours from '../../components/OpeningHours'

export default class ContactUs extends React.Component {
  render() {
    return (
      <div>
        <Section name="contact-us" className="hero contact-us">
            <div className="columns is-desktop">
                <div className="column is-two-thirds map">
                <Map />
              </div>
              <div className="column contact">
                <div className="contact-info">
                  <h4 className="is-heading">Find Us</h4>
                  <p>37 John William Street</p>
                  <p>Huddersfield HD1 1BL</p>
                  <p>Tel: <a href="tel:+0">01484 535 440</a></p>
                  <p>E-mail: <a href="mailto:info@botafogo.co.uk">info@botafogo.co.uk</a></p>
                  <hr className="dotted" />
                </div>

                <div className="opening-hours">
                  <OpeningHours />
                </div>

                <div className="social-media">
                  <h4 className="is-heading">Social  Media</h4>
                    <a
                      href="https://www.instagram.com/BotafogoGrill"
                      target="_blank"><i className="fa fa-instagram" /></a>
                    <a
                      href="https://www.facebook.com/BotafogoGrillHuddersfield/"
                      target="_blank"><i className="fa fa-facebook" /></a>
                      <a
                        href="https://www.tripadvisor.co.uk/Restaurant_Review-g190748-d3619108-Reviews-Botafogo_Brazilian_Grill-Huddersfield_West_Yorkshire_England.html" target="_blank"><i className="fa fa-tripadvisor" /></a>
                    <a
                      href="https://twitter.com/BotafogoGrill"
                      target="_blank"><i className="fa fa-twitter" /></a>
                </div>
              </div>
            </div>
          </Section>
      </div>

    )
  }
}
