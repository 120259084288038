import React from 'react'
import './ResDiaryBookingWidget.scss'

export default class ResDiaryBookingWidget extends React.Component {

  render() {
    return (
      <iframe
        id="resdiary-booking-widget"
        height={900}
        src="https://svtables.com/widget/?rest_id=559"
      />

    )
  }
}
