import React from 'react'
import PropTypes from 'prop-types'
import BotaText from '../BotaText'
import './BotaTextCircle.scss'

export default class BotaTextCircle extends React.Component {

  static propTypes = {
    alignCenter: PropTypes.bool,
    content: PropTypes.array.isRequired,
    colour: PropTypes.string
  }

  static defaultProps = {
    alignCenter: false,
    colour: "default"
  }

  render() {
    const { alignCenter, content, colour } = this.props
    return (
      <div className={`btc btc-${colour}`}>
        <div className="btc-text-wrapper">
          {
            content.map((item, i) => {
              return (
                <span key={`bota-text-circle-${i}`}>
                  <BotaText
                    
                    content={item}
                    size="2.9rem"
                    height="50"
                    width="240"
                    colour={colour}
                    alignCenter
                    />
                </span>
              )
            })
          }
        </div>
      </div>
    )
  }
}
