import React from 'react'
import PropTypes from 'prop-types'
import './BotaText.scss'

export default class BotaText extends React.Component {

  static propTypes = {
    content: PropTypes.string.isRequired,
    colour: PropTypes.string,
    size: PropTypes.string,
    alignCenter: PropTypes.bool
  }

  static defaultProps = {
    colour: "pink",
    size: "1rem",
    width: "320",
    height: "60",
    alignCenter: false
  }

  render() {
    const { alignCenter, content, colour, height, size, width } = this.props
    return (

          <svg
            className={`bota-${colour}`}
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            version="1.1"
          >

          <text
            x={alignCenter ? "50%" : "4%"}
            y="35"
            className="bota-text bota-text--wrapper-colour"
            style={{fontSize: size}}
            textAnchor={alignCenter ? "middle" : "start"}>
            {content}
          </text>
          <text
            x={alignCenter ? "50%" : "4%"}
            y="35"
            className="bota-text bota-text--wrapper-white"
            style={{fontSize: size}}
            textAnchor={alignCenter ? "middle" : "start"}>
            {content}
          </text>
          <text
            x={alignCenter ? "50%" : "4%"}
            y="35"
            className="bota-text bota-text--body-colour"
            style={{fontSize: size}}
            textAnchor={alignCenter ? "middle" : "start"}>
            {content}
          </text>

      </svg>
    )
  }
}
